import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from "single-spa-layout";
import { registerApplication, start } from "single-spa";

const urlParams = new URLSearchParams(window.location.search);
const standalonePram = urlParams.get(`standalone`);
const routes = constructRoutes({
  containerEl: `#app`,
  routes: [
    { type: `application`, name: `/scripts/header/header.js` },
    {
      type: `main`,
      routes: [
        {
          type: `route`,
          default: true,
          routes: [
            {
              type: `application`,
              name: `/scripts/dashboard/dashboard.js`,
            },
          ],
        },
        {
          type: `route`,
          path: `access-control-management`,
          routes: [
            {
              type: `application`,
              name: `/scripts/acm/acm.js`,
            },
          ],
        },
        {
          type: `route`,
          path: `logistics/image-management`,
          routes: [
            {
              type: `application`,
              name: `/scripts/logistics-image-management/logistics-image-management.js`,
            },
          ],
        },
      ],
    },
  ],
});

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach((app) => {
  if (app.name === `/scripts/header/header.js`) {
    app.activeWhen = () => standalonePram === null;
  }

  registerApplication(app);
});
layoutEngine.activate();
start();
